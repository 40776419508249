/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import 'antd/dist/antd.css'
import "./layout.css"
import Footer from "./footer";
import { StateContext } from "../state/store";
import { Spin, Icon } from "antd";

const loadingIcon = <Icon type="more" style={{ fontSize: 64 }} spin />;

class Layout extends React.Component {
  static contextType = StateContext;

  static propTypes = {
    children: PropTypes.node.isRequired,
    isModal: PropTypes.bool,
    hideActions: PropTypes.bool,
  }

  static defaultProps = {
    isModal: false,
    hideActions: false,
  }

  render() {
    const [{ loading }] = this.context;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <Spin spinning={loading} indicator={loadingIcon} style={{ height: '100%' }}>
            <Header siteTitle={data.site.siteMetadata.title} isModal={this.props.isModal} hideActions={this.props.hideActions} />
            <div
              style={{
                margin: `0 auto`,
                fontFamily: 'Open Sans',
                maxWidth: 1000,
                minHeight: `calc(100vh - 172px)`,
                padding: `0px 20px 80px`,
                paddingTop: 0,
                marginBottom: 100
              }}
            >
              <main>{this.props.children}</main>
            </div>
            <Footer />
          </Spin>
        )}
      />
    );
  }
}



export default Layout
