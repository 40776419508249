import PropTypes from "prop-types"
import React from "react"
import LogoImage from "@mc/common/components/mimecast-logo-white";
import BackButton from "./back-button";

const Header = ({ siteTitle, isModal, hideActions }) => (
  <header
    style={{
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        background: '#1976D2',
        margin: '0 auto',
        width: '100%',
        height: 52,
        display: 'flex',
        justifyContent: 'space-between',
        boxShadow: '0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)',
      }}
    >
      <div style={{
        maxWidth: 1000,
        margin: 'auto',
        width: '100%',
      }}>
        <div style={{
          maxWidth: '128px',
          maxHeight: '22px',
          marginTop: '12px',
          marginBottom: '18px',
          marginLeft: '20px',
        }}>
          <LogoImage />
        </div>
        {hideActions ? null : (isModal && <BackButton />)}
      </div>
    </div>
  </header >
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  isModal: PropTypes.bool,
  hideActions: PropTypes.bool,
}

Header.defaultProps = {
  siteTitle: ``,
  isModal: false,
  hideActions: false,
}

export default Header
