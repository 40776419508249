import React from "react"
import { Button, Icon } from "antd";

const BackButton = (props) => {
  return (
    <div>
      <Button shape="circle" size="large" onClick={() => window.history.back()}><Icon type="close" /></Button>
    </div>
  );
}

export default BackButton
