import React from "react"
import { Row, Col } from "antd";
import { Link, navigate } from "gatsby";
import { setUser } from "@mc/common/lib/auth";

function logout(e) {
    e.preventDefault()
    setUser({
        loggedIn: false,
    })
    navigate('/login')
}
const Footer = () => (
    <footer style={{
        backgroundColor: '#EEE', padding: 20, position: 'fixed',
        bottom: '0px',
        width: '100%',
    }}>
        <Row>
            <Col span={18}>
                ©{new Date().getFullYear()} Mimecast v4.2.29 20200813-202704
            </Col>
            <Col span={6}>
                <div style={{ textAlign: 'right' }}>
                    <Link onClick={logout} to="/login">Logout</Link>
                </div>
            </Col>
        </Row>
    </footer>
)

export default Footer
